import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 5×2\\@90% 1RM`}</p>
    <p>{`Pendlay Row 5×2\\@90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`10-HSPU’s`}</p>
    <p>{`15-V Ups`}</p>
    <p>{`20-KBS (53/35)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Sign up for the Support Your Local Box at home competition today
at: `}</em></strong><a parentName="p" {...{
        "href": "https://games.crossfit.com"
      }}><strong parentName="a"><em parentName="strong">{`https://games.crossfit.com`}</em></strong></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      